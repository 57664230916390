import https from "./https";
import store from "@/store";

const organization = store.state.liffGeneral.orgCode;

const form = {
  getForm(id, params) {
    return https.get(`${organization}/liff/forms/${id}`, { params });
  },
  saveResult(id, data) {
    return https.post(`${organization}/liff/forms/${id}/save-result`, data);
  },
};

export default form;
